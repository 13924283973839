import styled from "styled-components";

const Icon = styled.a`
  display: inline-block;
  width: 36px;
  height: 36px;
  border-radius: 24px;
  background-color: ${(props) =>
    props.background ? props.background : "#fff"} !important;
  // padding-right: 4px;

  margin: 0 5px 0px 1px;

  text-align: center;
  -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.1);
  line-height: 3px;

  span {
    color: ${(props) => (props.color ? props.color : "#707070")} !important;
    line-height: 36px;
    font-size: 16px;
    margin: 0px !important;
  }
`;

export default Icon;

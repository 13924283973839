// import App from "next/app";
import React, { useState, useEffect } from "react";
import MyLayout from "../components/utils/MyLayout";
import { AppProvider } from "../components/utils/Context";
import { useData } from "../utils/useData";
import "../public/static/scss/styles.scss";

function MyApp({ Component, pageProps }) {
  const [state, setState] = useState({
    personal: [],
    portfolio: [],
  });

  let personal = useData("personal");
  let navigation = useData("navigation");

  useEffect(() => {
    setState({
      personal,
      navigation,
    });
  }, [personal, navigation]);

  return (
    <AppProvider value={state}>
      <MyLayout>
        <Component {...pageProps} />
      </MyLayout>
    </AppProvider>
  );
}

export default MyApp;

import firebase from "firebase/app";
import "firebase/storage";
import "firebase/database";
import flamelink from "flamelink/app";
import "flamelink/content";
import "flamelink/storage";

const config = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: "https://mycv-7f70d.firebaseio.com",
  storageBucket: "mycv-7f70d.appspot.com",
};
if (!firebase.apps.length) {
  firebase.initializeApp(config);
  // firebaseApp = firebase.initializeApp(config);
}

var firebaseApp = firebase.app();

export const app = flamelink({
  firebaseApp,
  env: "production", // optional, defaults to `production`
  locale: "en-US", // optional, defaults to `en-US`
  dbType: "rtdb", // optional, defaults to `rtdb` - can be 'rtdb' or 'cf' (Realtime DB vs Cloud Firestore)
});
export const db = firebase.database();
export const storage = firebase.app().storage("gs://mycv-7f70d.appspot.com");

import Head from "next/head";
import React, { Component } from "react";

export default class Meta extends Component {
  render() {
    return (
      <Head>
        <meta charSet="UTF-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width" />
        <meta name="description" content="Esteban Flores CV" />
        <meta name="keywords" content="Esteban Flores CV" />
        <meta name="author" content="lmtheme" />
        <title>Esteban Flores</title>
        <link rel="shortcut icon" href="../static/images/EF_Logo.png" />

        <link rel="preload" href="../static/css/bootstrap.min.css" as="style" />
        <link rel="preload" href="../static/css/main.css" as="style" />
        <link rel="preload" href="../static/css/animate.css" as="style" />
        <link
          rel="preload"
          href="../static/css/material-design-iconic-font.min.css"
          as="style"
        />
        <link rel="preload" href="../static/css/font-awesome.css" as="style" />

        <link
          rel="stylesheet"
          href="../static/css/bootstrap.min.css"
          type="text/css"
        />
        <link rel="stylesheet" href="../static/css/main.css" type="text/css" />
        <link
          rel="stylesheet"
          href="../static/css/animate.css"
          type="text/css"
        />
        <link
          href="https://cdnjs.cloudflare.com/ajax/libs/material-design-iconic-font/2.2.0/css/material-design-iconic-font.min.css"
          as="style"
        />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://www.gstatic.com" />
        <link rel="preconnect" href="https://firebasestorage.googleapis.com" />
        <link rel="preconnect" href="http://res.cloudinary.com" />

        <title>Esteban Flores</title>
      </Head>
    );
  }
}

import styled from "styled-components";
import { respondTo } from "../styles/_respondTo";

export const HeaderLink = styled.a`
  color: #fff;
  line-height: 1;
  margin: 38px 0 0;
  cursor: pointer;
  span {
    color: #fff;
    &.hover {
      color: #49515d;
    }
  }
  &.active {
    color: #49515d;
    text-decoration: none;
  }
  &.hover {
    color: #006aa3;
  }

  ${respondTo.md`
    color: #49515d;
    span{
      color: #006aa3;
    }
  `}
`;

export const HeaderMobile = styled.div`
  width: 100%;
  max-width: 100%;
  left: 0;
  right: 0;
  height: 50px;
  background-color: #006aa3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 10;
  ${respondTo.md`
    display: none;
  `};
`;

export const HeaderNav = styled.div`
  width: 100%;
  max-width: 100%;
  left: 0;
  right: 0;
  height: 50px;
  background-color: #006aa3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 10;
  ${respondTo.md`
    display: none;
  `};
`;

import React, { useState, useEffect, createRef, useContext } from "react";

import { AppConsumer, AppContext } from "../utils/Context";
import Loading from "../utils/Loading";
import Link from "next/link";
import { useRouter } from "next/router";
import { HeaderLink, HeaderMobile } from "./Styles";
import Icon from "../styles/Icons";

const Header = () => {
  const [visible, setVisible] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [active, setActive] = useState("");
  const router = useRouter();
  const mobileMenu = createRef();
  const { navigation } = useContext(AppContext);

  useEffect(() => {
    setActive(router.pathname);
    const handleScroll = () => {
      window.scrollY >= 10 ? setScroll(true) : setScroll(false);
    };

    const handleClickOutside = (e) => {
      mobileMenu.current != null &&
      mobileMenu.current.contains(e.target) &&
      !visible
        ? setVisible(true)
        : setVisible(false);
    };

    // For the menu appearing when user scrolls down
    window.addEventListener("scroll", handleScroll);
    // For when the user click outside the menu on mobile devices
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Remove event listener from scrolling
      window.removeEventListener("scroll", handleScroll);
      // Remove event listener from click on the menu
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [mobileMenu, scroll, visible, router]);

  return !navigation || navigation.length <= 0 ? (
    <Loading />
  ) : (
    <>
      <header
        id="site_header"
        role="banner"
        className={`header ${!visible && "mobile-menu-hide"} ${
          scroll && "sticked"
        }`}
      >
        <div className="header-content" role="heading" aria-level="1">
          <div className="site-title-block mobile-hidden">
            <div className="site-title text-capitalize">
              <Link href="/" passHref>
                <HeaderLink>
                  Esteban<span>Flores</span>
                </HeaderLink>
              </Link>
            </div>
          </div>

          {/* Main Menu */}
          <nav className="site-nav" role="navigation">
            <ul id="nav" className="site-main-menu">
              {navigation
                .filter((item) => item["en-US"].id != "contact")
                .map((navitem) => {
                  const { id, title } = navitem["en-US"];

                  return (
                    <li
                      key={id}
                      className={
                        active == `/${title.toLowerCase()}` && active != "/"
                          ? "active"
                          : ""
                      }
                      // onClick={setActive(router.pathname)}
                    >
                      <Link passHref href={`/${id}`}>
                        <HeaderLink>{title}</HeaderLink>
                      </Link>
                    </li>
                  );
                })}
            </ul>
          </nav>
        </div>
      </header>

      {/*Header Mobile*/}
      <HeaderMobile role="banner">
        <div className="mobile-logo-container">
          <div className="mobile-site-title text-capitalize">
            <Link href="/" passHref>
              <HeaderLink>
                Esteban <span>Flores</span>
              </HeaderLink>
            </Link>
          </div>
        </div>

        <Icon
          className="menu-toggle mobile-visible"
          ref={mobileMenu}
          // aria-pressed="false"
          ref={mobileMenu}
          href="#"
          aria-label="mobile menu"
        >
          <span className={visible ? "fa fa-times" : "fa fa-bars"} />
        </Icon>
      </HeaderMobile>
      {/*Header Mobile*/}
    </>
  );
};

export default Header;

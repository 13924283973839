import { useState, useEffect } from "react";
import { db, app } from "./base";

// takes the reference ID that is set on firebase (eg: personal,portfolio)
export const useData = (collection) => {
  const [data, setData] = useState([]);
  const [portfolio, setPort] = useState([]);

  var collectionPath =
    collection == "navigation"
      ? `flamelink/environments/production/${collection}`
      : `flamelink/environments/production/content/${collection}/en-US`;

  // const getImageURL = async () => {
  //   console.log(true);
  //   data.forEach(async (item) => {
  //     item.mediaURL = await app.storage.getURL({ fileId: item.media[0] });
  //     console.log(item);
  //   });
  // };

  useEffect(() => {
    async function getImageURL() {
      db.ref(collectionPath).on("value", (snapshot) => {
        // transform to an array of objects
        const arrayOfObj = Object.entries(snapshot.val()).map((e) => e[1]);

        if (collection == "portfolio") {
          arrayOfObj.forEach(async (item) => {
            item.mediaURL = await app.storage.getURL({ fileId: item.media[0] });
          });
        }
        setData(arrayOfObj);
      });
    }
    getImageURL();
  }, [collectionPath]);
  return data;
};

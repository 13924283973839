import React, { Component } from "react";
import Header from "../Header/Header";
import Meta from "../utils/Meta";
import { AppConsumer } from "../utils/Context";

const MyLayout = (props) => {
  return (
    <>
      <Meta />
      <Header />

      <div className="site-main" id="site-main">
        <div className="pt-wrapper">
          <div className="subpages">
            <main className="pt-page" id="sectionContent" role="main">
              <div className="section-inner start-page-content swing-in-top-bck">
                {props.children}
              </div>
            </main>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyLayout;
